<template>
  <div class="states_box">
    <div>
      <img
        :src="require(`@/assets/images/state0.png`)"
        alt=""
      />
      <span>可选</span>
    </div>
    <div>
      <img
        :src="require(`@/assets/images/state2.png`)"
        alt=""
      />
      <span>不可选</span>
    </div>
    <div>
      <img
        :src="require(`@/assets/images/state1.png`)"
        alt=""
      />
      <span>已选</span>
    </div>
  </div>
  <div class="checks_box">
    <div
      v-for="(item, index) in checks"
      :key="index"
      class="item"
    >
      <span>{{ item.abscissa }}排{{ item.ordinate }}号</span>
      <van-icon
        name="cross"
        @click="checkDel(index)"
      />
    </div>
  </div>
  <div class="movieSeat">
    <div class="code_box">
      <div class="top_code">
        <div>
          <template v-for="(y, index) in top_codeList">
            <div
              :key="index"
              v-if="y"
            >
              {{ y || "" }}
            </div>
          </template>
        </div>
      </div>
      <div class="left_code">
        <div>
          <template v-for="(x, index) in left_codeList">
            <div
              :key="index"
              v-if="x"
            >
              {{ x || "" }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="plans">
      <div
        v-for="(x, x_i) in height"
        :key="x + x_i"
        style="display: flex"
      >
        <template v-for="(y, y_i) in width">
          <div
            :key="y + y_i"
            v-if="planData[getId(x_i, y_i)] !== 9"
          >
            <div
              class="seat"
              @click="onSeat(x_i, y_i)"
            >
              <img
                v-if="showState(x_i, y_i)"
                :src="require(`@/assets/images/${showState(x_i, y_i)}.png`)"
                alt=""
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // const { seatInfo, remarks, useSet } = this.config;
    const { seatInfo, remarks } = this.config;
    const seatInfoArray = seatInfo?.split(",").map((v) => Number(v));
    // useSet.forEach((item) => {
    //   let { x, y } = item;
    //   seatInfoArray[y * 21 + x] = 2;
    // });
    let data = {};
    if (remarks) {
      data = JSON.parse(remarks);
    }
    const {
      lineCodeType,
      lineCodeDirection,
      columnCodeType,
      columnCodeDirection,
    } = data;
    return {
      lineCodeType,
      lineCodeDirection,
      columnCodeType,
      columnCodeDirection,
      width: 26,
      height: 10,
      plansDom: null,
      positionList: {
        box_screen_left: 0,
        box_screen_top: 0,
      },
      seat: [],
      planData: seatInfoArray || new Array(26 * 10).fill(0),
      checks: [],
    };
  },
  props: {
    config: {
      type: Object,
      default: () => { },
    },
    totalNum: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    top_codeList() {
      let data = [];
      for (let i = 0; i < this.width; i++) {
        let flag = false;
        for (let ii = 0; ii < this.height; ii++) {
          const state = this.planData[this.width * ii + i];
          if (state !== 0 && state !== 9) flag = true;
        }
        if (flag) {
          const max = data.length === 0 ? 0 : Math.max(...data);
          data.push(max + 1);
        } else {
          data.push(0);
        }
      }
      if (this.columnCodeDirection === "2") {
        const max = Math.max(...data) + 1;
        data = data.map((v) => {
          if (v === 0) return 0;
          return max - v;
        });
      }
      if (this.columnCodeType === "2") {
        return data.map((v) => {
          return this.numberToLetter(v);
        });
      } else {
        return data;
      }
    },
    left_codeList() {
      let data = [];
      for (let i = 0; i < this.height; i++) {
        let flag = false;
        for (let ii = 0; ii < this.width; ii++) {
          const state = this.planData[this.width * i + ii];
          if (state !== 0 && state !== 9) flag = true;
        }
        if (flag) {
          const max = data.length === 0 ? 0 : Math.max(...data);
          data.push(max + 1);
        } else {
          data.push(0);
        }
      }
      if (this.lineCodeDirection === "2") {
        const max = Math.max(...data) + 1;
        data = data.map((v) => {
          if (v === 0) return 0;
          return max - v;
        });
      }
      if (this.lineCodeType === "2") {
        return data.map((v) => {
          return this.numberToLetter(v);
        });
      } else {
        return data;
      }
    },
  },
  created() { },
  mounted() {
    this.plansDom = document.getElementsByClassName("plans")[0];
    this.positionList.box_screen_left = this.plansDom.getClientRects()[0].left;
    this.positionList.box_screen_top = this.plansDom.getClientRects()[0].top;
    this.seat = document.getElementsByClassName("seat");
    this.seat = Array.from(this.seat);
    let planData = JSON.parse(JSON.stringify(this.planData));
    let w = [];
    for (let i = 0; i < this.width; i++) {
      w.push(9);
    }
    for (let i = 9; i >= 0; i--) {
      let arr = planData.slice(i * this.width, (i + 1) * this.width);
      let fun = arr.filter((num) => num === 0);
      if (fun.length === 26) {
        planData.splice(i * this.width, this.width, ...w);
      }
    }
    for (let i = this.width; i > 0; i--) {
      let arr = [];
      for (let j = 0; j < this.height; j++) {
        arr.push(planData[j * this.width + i - 1]);
      }
      let fun = arr.filter((num) => num === 0 || num === 9);
      if (fun.length === this.height) {
        for (let j = 0; j < this.height; j++) {
          planData.splice(j * this.width + i - 1, 1, 9);
        }
      }
    }
    this.planData = planData;
  },
  methods: {
    getId(y, x) {
      return this.width * y + x;
    },
    showState(y, x) {
      const state = this.planData[this.getId(y, x)];
      const data = ["", "state0", "state2", "state1"];
      return data[state];
    },
    onSeat(y, x) {
      const index = this.getId(y, x);
      const state = this.planData[index];
      if (state === 1) {
        if (this.totalNum > this.checks.length) {
          this.planData[index] = 3;
          this.checks.push({
            ordinate: this.top_codeList[x],
            abscissa: this.left_codeList[y],
            y,
            x,
          });
        } else {
          this.$notify({
            type: "danger",
            message: "选择的座位数不能超过预约总人数",
          });
        }
      } else if (state === 3) {
        this.planData[index] = 1;
        this.checks = this.checks.filter((v) => {
          return !(
            v.ordinate === this.top_codeList[x] &&
            v.abscissa === this.left_codeList[y]
          );
        });
      }
    },
    isCheck(id) {
      return this.planData[id];
    },
    numberToLetter(number) {
      if (!number) return number;
      return String.fromCharCode(64 + parseInt(number));
    },
    checkDel(index) {
      let { x, y } = this.checks[index];
      this.onSeat(y, x);
    },
  },
};
</script>
<style lang="scss" scoped>
.movieSeat {
  padding: 30px 30px 20px;
  overflow-x: auto;
  .code_box {
    position: relative;
    font-size: 12px;
    color: #666;
    .top_code {
      position: absolute;
      top: -20px;
      // width: 648px;
      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        > div {
          width: 25px;
          text-align: center;
        }
      }
    }
    .left_code {
      position: absolute;
      left: -20px;
      // height: 250px;
      > div {
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-direction: column;
        height: 100%;
        > div {
          height: 25px;
          line-height: 25px;
        }
      }
    }
  }
  .plans {
    display: flex;
    flex-direction: column;
    .seat {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.checks_box {
  color: #076cfa;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .item {
    width: calc((100% - 65px) / 4);
    margin-right: 5px;
    margin-bottom: 5px;
    height: 26px;
    background: #fff;
    border: 1px solid #076cfa;
    border-radius: 3px;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .van-icon {
      color: #fd4d4f;
    }
  }
  .item:nth-of-type(4n + 0) {
    margin-right: 0;
  }
}
.states_box {
  margin: 15px 0;
  display: flex;
  justify-content: space-evenly;
  > div {
    display: flex;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
    }
    span {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}
</style>
