<template>
  <div class="reservation">
    <van-sticky offset-top="1.227rem">
      <van-steps
        :active="active"
        active-color="#0B6CF9"
        @click-step="clickStep"
      >
        <van-step>选择票种</van-step>
        <van-step>选择人员</van-step>
        <van-step>选择座位</van-step>
        <van-step>确认支付</van-step>
      </van-steps>
    </van-sticky>
    <div class="box">
      <div class="title">
        <img
          src="@/assets/images/icon4.png"
          alt=""
        />
        <span>{{ query.filmName }}</span>
      </div>
      <div class="profile">
        <p>
          观影日期：<span>{{
            query.dayTime + " " + query.startTime + "-" + query.endTime
          }}</span>
        </p>
        <template v-if="active > 0">
          <template v-for="(item, index) in profiles">
            <p
              v-if="item.num > 0"
              :key="index"
              class="num_price"
            >
              <span>{{ item.name }}：{{ item.num }}人</span>
              <span :class="{ totalPrice: index === 0 }">￥{{ item.price }}</span>
            </p>
          </template>
        </template>
        <!-- <template v-if="active > 1">
          <p class="num_price">
            <span>已选座位:{{ seats }}</span>
          </p>
        </template> -->
      </div>
    </div>
    <template v-if="active === 0">
      <div
        class="quantity"
        v-for="(item, index) in quantitys"
        :key="index"
      >
        <div>
          <p>
            {{ item.typeName }}
            <span>￥{{ item.isOnline === 1 ? query.filmPrice : "0.00" }}</span>
          </p>
          <van-stepper
            v-model="item.num"
            theme="round"
            button-size="18"
            disable-input
            :min="0"
            :default-value="0"
            :disable-plus="disablePlus"
            :name="index"
            @change="stepperChange(item)"
            v-if="item.isOnline === 1"
          />
          <p v-else>线下售票</p>
        </div>
        <div
          v-if="item.ranges"
          class="ranges"
        >
          <span>适用范围：{{ item.ranges }}</span>
        </div>
      </div>
    </template>
    <template v-else-if="active === 1">
      <div class="box">
        <div class="title title_box">
          <span>联系人信息</span>
          <div
            class="add_btn"
            @click="addPersonnel(1, 'list', 'list2')"
          >
            添加/更换
          </div>
        </div>
        <div
          class="form_box"
          v-if="list.length"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="item"
          >
            <img
              src="@/assets/images/delete_p.png"
              alt=""
              @click="del(item, 'list')"
            />
            <div class="right">
              <div class="info">
                <p class="name">{{ item.name }}</p>
                <p>证件号码：{{ item.documentNumber }}</p>
                <p>手机号码：{{ item.phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <p
          v-else
          class="please_select"
        >请选择1位预约人</p>
      </div>
      <div
        class="box"
        v-if="profiles[0].num > 1"
      >
        <div class="title title_box">
          <span>随行人员信息</span>
          <div
            class="add_btn"
            @click="addPersonnel(profiles[0].num - 1, 'list2', 'list')"
          >
            添加/更换
          </div>
        </div>
        <div
          class="form_box"
          v-if="list2.length"
        >
          <div
            v-for="(item, index) in list2"
            :key="index"
            class="item"
          >
            <img
              src="@/assets/images/delete_p.png"
              alt=""
              @click="del(item, 'list2')"
            />
            <div class="right">
              <div class="info">
                <p class="name">{{ item.name }}</p>
                <p>证件号码：{{ item.documentNumber }}</p>
                <p>手机号码：{{ item.phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="list2.length < profiles[0].num - 1"
          class="please_select"
        >
          <span v-if="list2.length">还需再</span><span v-else>请</span>选择{{
            profiles[0].num - 1 - list2.length
          }}位随行人
        </p>
      </div>
    </template>
    <template v-else-if="active === 2">
      <div class="box">
        <div class="title"><span>选择座位</span></div>
        <div v-if="isMovieSeat">
          <MovieSeat
            :config="temp"
            :totalNum="profiles[0].num"
            ref="seat"
          />
          <img
            src="@/assets/images/screen.png"
            alt=""
            class="screen"
          >
        </div>
      </div>
    </template>
    <van-button
      type="primary"
      class="btn_sty"
      @click="nextStep"
      id="subscribeBtn"
    >
      {{
        active === 0
          ? "下一步 填写人员"
          : active === 1
          ? "下一步 选择座位"
          : active === 2
          ? "立即支付"
          : "确认支付"
      }}
    </van-button>
    <van-dialog
      v-model:show="show"
      class="appointment"
      confirm-button-text="知道了"
      confirm-button-color="#fff"
      @confirm="$router.replace('/')"
    >
      <template #title>
        <div class="dialog_tit">
          <van-icon name="checked" /><span>购买成功！</span>
        </div>
      </template>
      <p>在“我的—影票购买记录”中查看订单信息。</p>
    </van-dialog>
    <van-dialog
      v-model:show="show2"
      title="温馨提示"
      show-cancel-button
      confirmButtonText="确认支付"
      confirmButtonColor="#458aef"
      :confirmButtonDisabled="!reading"
      @confirm="movieBook"
      @close="close"
      class="confirm_tips"
    >
      <!-- <div class="content">
        <p>
          1、每个微信号每天限约<span style="color: #fd4d4f">{{
            data.resNums
          }}</span
          >次，用户主动退票<span style="color: #fd4d4f">不返还购买次数</span
          >，敬请谅解!
        </p>
        <p>
          2、为保障公众权益，杜绝有偿代约行为，请观众凭已预的的动态二维码进场观影，截图二维码无效。
        </p>
        <p></p>
      </div> -->
      <div
        class="content"
        v-html="data.tips"
      ></div>
      <van-checkbox
        v-model="reading"
        shape="square"
        icon-size="16px"
      >我已阅读并遵守以上规定</van-checkbox>
    </van-dialog>
    <MyNotice ref="myNotice" />
    <van-popup
      class="commonly_popup"
      v-model:show="commonlyShow"
      position="right"
    >
      <CommonlyUsed
        :max="max"
        :ids="ids"
        :disableds="disableds"
        @personnel="personnel"
      />
    </van-popup>
    <van-popup
      v-model:show="subscribe"
      position="bottom"
      class="subscribe"
    >
      <wx-open-subscribe
        :template="templateId"
        id="subscribe-btn"
        @success="openSubscribeSuccess"
        @error="openSubscribeError"
      >
        <component
          :is="'script'"
          type="text/wxtag-template"
        >
          <div style="
              width: 300px;
              color: #fff;
              background-color: #07c160;
              line-height: 40px;
              height: 40px;
              text-align: center;
              font-size: 16px;
              border: 1px solid #07c160;
              border-radius: 5px;
            ">
            消息推送授权
          </div>
        </component>
      </wx-open-subscribe>
    </van-popup>
  </div>
</template>
<script>
import MovieSeat from "@/components/MovieSeat.vue";
import MyNotice from "@/components/MyNotice.vue";
import CommonlyUsed from "./CommonlyUsed.vue";
import {
  submit_purchase,
  polling,
  type_list,
  config_get,
  show_ticket_find,
} from "@/api/MovieTicketReservation";
import { identify } from "@/api/api";
import { contactList } from "@/api/CommonlyUsed";
export default {
  name: "Reservation",
  data() {
    return {
      active: 0,
      profiles: [],
      quantitys: [],
      show: false,
      show2: false,
      temp: {},
      chooseSeatsXy: "",
      seats: "",
      data: {},
      isMovieSeat: false,
      query: {},
      disablePlus: false,
      list: [],
      list2: [],
      contactList: [],
      max: 0,
      ids: [],
      disableds: [],
      commonlyShow: false,
      commonlyActive: "",
      setTime: null, //定义setTimeout的定时器名称
      templateId: [
        "g70fCJ91wpy6UDU_HR7vxUV44Ku59ucHsrGB2GaIbqw",
        "XrJjNqE_Z1zXwylqI7eRu55wFFgtgDh5cvzweqxdXis",
      ],
      subscribe: false,
      id: null,
      reading: false,
    };
  },
  props: {
    clickRightNum: {
      type: Number,
      default: () => 0,
    },
    clickLeftNum: {
      type: Number,
      default: () => 0,
    },
  },
  components: { MovieSeat, MyNotice, CommonlyUsed },
  created() {
    this.id = this.$route.query.id;
    this.getFind();
    this.getConfig();
    this.getTypeList();
    this.getIdentify();
  },
  mounted() { },
  methods: {
    async getFind() {
      let { data } = await show_ticket_find(this.id);
      this.query = data;
    },
    async getIdentify() {
      let {
        data: { appId, nonceStr, signature, timestamp },
      } = await identify({
        url: this.$store._state.data.href,
      });
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: [], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-subscribe"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
    },
    async getConfig() {
      const { data } = await config_get();
      this.data = data;
    },
    async getTypeList() {
      let { data } = await type_list();
      data.map((v) => {
        v.num = 0;
      });
      this.quantitys = data;
    },
    del(item, active) {
      this[active] = this[active].filter((item2) => item2.id !== item.id);
    },
    async personnel(ids) {
      let { data } = await contactList();
      this.contactList = data;
      let arr = [];
      ids.map((item) => {
        arr = arr.concat(this.contactList.filter((item2) => item2.id === item));
      });
      this[this.commonlyActive] = arr;
      this.commonlyShow = false;
    },
    addPersonnel(num, active, active2) {
      let ids = [];
      let ids2 = [];
      this.commonlyActive = active;
      this[active].map((item) => {
        ids.push(item.id);
      });
      this[active2].map((item) => {
        ids2.push(item.id);
      });
      this.disableds = ids2;
      this.ids = ids;
      this.max = num;
      this.commonlyShow = true;
    },
    async getMovieSet() {
      this.isMovieSeat = false;
      let { seatInfo, remarks } = this.query;
      this.temp = {
        seatInfo: seatInfo,
        remarks,
      };
      this.$nextTick(() => {
        this.isMovieSeat = true;
      });
    },
    //下一步
    async nextStep() {
      if (this.active === 0) {
        let filmPrice = Number(this.query.filmPrice);
        this.profiles = [{ name: "预约总人数", price: 0, num: 0 }];
        this.quantitys.forEach((item) => {
          this.profiles[0].num += item.num;
          this.profiles[0].price += item.num * filmPrice;
          this.profiles.push({
            id: item.id,
            name: item.typeName,
            num: item.num,
            price: item.num * filmPrice,
          });
        });
        if (this.profiles[0].num > 0) {
          this.list = [];
          this.list2 = [];
          this.active++;
        } else {
          this.$notify({ type: "danger", message: "至少选择一张票" });
        }
      } else if (this.active === 1) {
        let validate = true;
        if (!this.list.length) {
          this.$notify({
            type: "danger",
            message: "请添加联系人信息",
          });
          validate = false;
        }
        if (this.list2.length < this.profiles[0].num - 1 && validate) {
          this.$notify({
            type: "danger",
            message: "请添加随行人员信息",
          });
          validate = false;
        }
        if (validate) {
          await this.getFind();
          await this.getMovieSet();
          this.chooseSeatsXy = "";
          this.seats = "";
          this.active++;
        }
      } else if (this.active === 2) {
        const checks = this.$refs.seat.checks;
        if (checks.length === this.profiles[0].num) {
          const seats = [];
          const chooseSeatsXy = [];
          checks.forEach((item) => {
            let { x, y, abscissa, ordinate } = item;
            chooseSeatsXy.push(x + ":" + y);
            seats.push(abscissa + "排" + ordinate + "号");
          });
          this.chooseSeatsXy = chooseSeatsXy.join(",");
          this.seats = seats.join(",");
          // this.active++;
          this.show2 = true;
        } else {
          this.$notify({ type: "danger", message: "请给每个人选一个座位" });
        }
      }
    },
    //预约提交
    movieBook() {
      this.subscribe = true;
      // this.submit();
    },
    close() {
      this.reading = false;
    },
    //预约提交
    async submit() {
      let { filmPrice, id } = this.query;
      filmPrice = Number(filmPrice);
      const showTicketOrderTVOList = [];
      this.quantitys.map((v) => {
        let { num, id, typeName, isOnline } = v;
        if (v.num > 0 && isOnline === 1) {
          showTicketOrderTVOList.push({
            nums: num,
            typeId: id,
            typeName,
            prices: num * filmPrice,
          });
        }
      });
      const votes = this.profiles[0].num;
      const wxpId = this.list[0].id;
      const wxpIds = [];
      this.list2.map((v) => {
        wxpIds.push(v.id);
      });
      const data = {
        chooseSeats: this.seats,
        chooseSeatsXy: this.chooseSeatsXy,
        price: filmPrice,
        prices: filmPrice * votes,
        votes,
        reorderInfoId: Number(id),
        showTicketOrderTVOList,
        wxpId,
        wxpIds: wxpIds.join(","),
      };
      let {
        data: { payUrl },
      } = await submit_purchase(data);
      // this.time = setInterval(() => {
      //   // 判断是否支付成功
      //   polling({ orderNo }).then((res) => {
      //     if (res.code == 200) {
      //       this.time && this.clearTimer(); //清除定时器
      //       this.show = true;
      //       this.active++;
      //     }
      //   });
      // }, 1000);
      window.location.href = payUrl;
    },
    //票数增加
    stepperChange() {
      let max = 0;
      this.quantitys.map((v) => {
        max = max + v.num;
      });
      if (max >= this.data.resPpl) {
        this.disablePlus = true;
      } else {
        this.disablePlus = false;
      }
    },
    //点击步骤的标题或图标
    clickStep(index) {
      if (index < this.active) {
        this.active = index;
      }
    },
    openSubscribeSuccess() {
      this.submit();
      this.subscribe = false;
    },
    openSubscribeError() {
      this.submit();
      this.subscribe = false;
    },
  },
  watch: {
    //点击预约须知
    clickRightNum() {
      this.$refs.myNotice.init("影票购买", false, this.data.notice);
    },
    //点击返回
    clickLeftNum() {
      if (this.active) {
        this.active--;
      } else {
        // this.$router.go(-1);
        let type = "";
        if (this.query.cinemaName === "4D影院") {
          type = "4D影票购买";
        } else if (this.query.cinemaName === "球幕影院") {
          type = "球幕影票购买";
        }
        this.$router.replace({
          path: "/shadowTicket",
          query: { type },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reservation {
  height: 100%;
  background: #f5f6fa;
  min-height: calc(100vh - 92px);
  padding-bottom: 46px;
  ::v-deep .van-sticky {
    background: #fff;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    .van-steps {
      width: 100%;
      padding: 0 50px;
      .van-steps__items {
        .van-hairline {
          .van-step__circle-container,
          .van-step__line {
            top: 38px;
          }
          .van-step__title {
            font-size: 16px;
          }
          .van-step__circle-container {
            .van-icon {
              font-size: 16px;
            }
            .van-step__circle {
              width: 10px;
              height: 10px;
            }
          }
        }
        .van-step--horizontal:first-child {
          .van-step__title {
            margin-left: -20px;
          }
        }
        .van-step--horizontal:last-child {
          .van-step__title {
            margin-right: -20px;
          }
        }
      }
    }
  }
  .box {
    margin: 10px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    .title {
      padding-bottom: 10px;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: rgb(51, 51, 51);
      }
      .add_btn {
        font-size: 14px;
        font-weight: 400;
        color: #0b6cf9;
      }
    }
    .screen {
      width: 100%;
    }
    .please_select {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      margin-top: 10px;
    }
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .profile {
      margin-top: 10px;
      p {
        font-size: 14px;
        color: #666;
        line-height: 24px;
      }
      .num_price {
        display: flex;
        justify-content: space-between;
        .totalPrice {
          color: #fd4d4f;
          font-size: 18px;
        }
      }
    }
  }
  ::v-deep .form_box {
    background: #fdfdfd;
    border-radius: 5px;
    .item {
      border-bottom: 1px solid #dbdbdb;
      background-color: #fff;
      padding: 10px 20px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        display: block;
      }
      .right {
        width: 100%;
        padding: 0 10px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          p {
            font-size: 13px;
            line-height: 20px;
          }
          .name {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .quantity {
    margin: 10px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      > p {
        color: #333;
        font-size: 14px;
        span {
          color: #fd4d4f;
        }
      }
      > span {
        color: #999;
        font-size: 12px;
      }
      ::v-deep .van-stepper {
        height: 30px;
        line-height: 30px;
        .van-stepper__minus {
          color: #0b6cf9;
          border: 1px solid #0b6cf9;
        }
        .van-stepper__plus {
          color: #0b6cf9;
          background: #fff;
          border: 1px solid #0b6cf9;
        }
      }
    }
    .ranges {
      margin-top: 10px;
    }
  }
  .btn_sty {
    position: fixed;
    bottom: 0;
    width: 100%;
    line-height: 44px;
    height: 44px;
    font-weight: 650;
    font-size: 16px;
    color: #fff;
  }
  ::v-deep .confirm_tips {
    .van-dialog__header {
      padding: 0;
      line-height: 50px;
      font-weight: bold;
    }
    .van-dialog__content {
      padding: 0 15px 10px;
      font-size: 16px;
      font-weight: 400;
      .content {
        line-height: 1.8;
        overflow-y: auto;
        max-height: 66vh;
      }
      .van-checkbox {
        margin-top: 10px;
        .van-checkbox__label {
          color: #1989fa;
        }
      }
    }
  }
  ::v-deep .appointment {
    .van-dialog__header {
      padding: 15px 0;
      font-size: 16px;
      color: #333333;
      font-weight: 650;
      .dialog_tit {
        display: flex;
        justify-content: center;
        align-items: center;
        > i {
          color: #4bd863;
          font-size: 22px;
          margin-right: 10px;
        }
      }
    }
    .van-dialog__content {
      padding: 0 20px 20px;
      .tips {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #333;
        line-height: 24px;
      }
    }
    .van-dialog__footer {
      .van-button {
        background-color: #3069f0;
      }
    }
  }
  ::v-deep .commonly_popup {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    .commonlyUsed {
      height: 100vh;
      .van-checkbox-group {
        margin-bottom: 60px;
      }
    }
  }
  ::v-deep .subscribe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
</style>
