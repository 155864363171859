import request from "@/utils/request";

// 提交购买
export const submit_purchase = (data) => {
  return request({
    url: "/we_chat/show_ticket/submit_purchase",
    method: "post",
    data,
  });
};

// 订单状态轮询查询
export const polling = (params) => {
  return request({
    url: "/we_chat/show_ticket/polling",
    method: "get",
    params,
  });
};

// 立即预约
export const reservation = (params) => {
  return request({
    url: "/we_chat/show_ticket/reservation",
    method: "get",
    params,
  });
};

// 查询基础设置
export const config_get = () => {
  return request({
    url: "/admin/show_ticket/config/get",
    method: "get",
  });
};

// 票务类型-列表
export const type_list = () => {
  return request({
    url: `/admin/show_ticket/type/list`,
    method: "get",
  });
};

// 影票-影票详情
export const show_ticket_find = (id) => {
  return request({
    url: `/we_chat/show_ticket/find/${id}`,
    method: "get",
  });
};
